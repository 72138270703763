import './App.css';
import Header from './component/Header';
import Mainpage from './component/mainpage';
import Footer from './component/footer';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import '../src/assets/css/style.css';
import '../src/assets/css/font-awesome.css';
import Web3 from "web3";
import { useEffect, useState } from 'react';
import { data } from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { socket } from './Service/socket';
import { isEmptyArray } from 'formik';
import { object } from 'yup';


const ethereum = window.ethereum;

function App() {
  const [address, setAddress] = useState(null);
  const [chainid, setChainid] = useState(null);
  const [ethvalue, setEthValue] = useState("");

  const bscnet = {
    network: "Binance",
    tokenName: "BSC"
  }
  const Ethnet = {
    network: "Ethereum",
    tokenName: "ETH"
  }
  const RCLnet = {
    network: "Realchain",
    tokenName: "Realchain RCL"
  }
  
  const [FromState, setFromState] = useState(bscnet);
  const [FromtokenState, setFromtokenState] = useState();
  const [TotokenState, setToTokenState] = useState();
  const [ToState, setToState] = useState(RCLnet);
  const [FromchainData, setFromchainData] = useState();
  const [FromchaintokenData, setFromchaintokenData] = useState();
  const [TochainData, setTochainData] = useState();
  const [TochaintokenData, setTochaintokenData] = useState();
  const [chainState, setChainState] = useState(
    [{
      From: "Binance",
      To: "Realchain",
      FromSymbol: "BSC"
    }, 
    // {
    //   From: "Ethereum",
    //   To: "Realchain",
    //   FromSymbol: "Ethereum"
    // }
  ]
  )

  const changChain = (from, to) => {
    if (from.network === "Binance") {
      binanceConnect();
      setFromState(from);
      setToState(to)
    } else if (from.network === "Ethereum") {
      ethereumConnect();
      setFromState(from);
      setToState(to)
    }
    getChainData(from.network)
  }
  useEffect(() => {
    changChain(bscnet, RCLnet)
  }, [])
  useEffect(() => {
    getFromChaintokenData(FromState.network)
  }, [FromState])
  useEffect(() => {
    getToChaintokenData(ToState.network)
  }, [ToState])
  useEffect(() => {
    socket.on('getchain-res', (value) => {
      if (!isEmptyArray(value) && typeof (value) === "object") {

        if (value[0]?.chain_name === FromState?.network) {
          setFromchainData(value[0])

        }
        else if (value[0]?.chain_name === ToState?.network) {

          setTochainData(value[0])

        }
      }
    })
    socket.on("tokens-from-res", (value) => {

      setFromchaintokenData(value)
    })
    socket.on("tokens-to-res", (value) => {

      setTochaintokenData(value)
    })
  }, [FromState])

  
  const getChainData = (value) => {
    if (typeof (value) !== "undefined" && value !== undefined); {
      socket.emit("getchain", ({ chainname: value }))
    }
  }

  const getFromChaintokenData = (value) => {
    if (typeof (value) !== "undefined" && value !== undefined); {
      socket.emit("tokens-from", ({ chainname: value }))
    }

  }
  const getToChaintokenData = (value) => {
    if (typeof (value) !== "undefined" && value !== undefined); {
      socket.emit("tokens-to", ({ chainname: value }))
    }
  }
  const handleConnectWallet = async () => {
    if (window.ethereum) {
      try {
        let web3 = new Web3(window.ethereum);
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        let getChainID = await web3.eth.getChainId();
        setAddress(accounts[0])
        if (getChainID = 56) {
          if (accounts[0]) {
            success("Connected successfully")
            setFromtokenState()
            setToTokenState()
          }
        } else if (getChainID === 121) {
          if (accounts[0]) {
            success("Connected successfully")
            setFromtokenState()
            setToTokenState()
          }
        } else if (getChainID === 1) {
          if (accounts[0]) {
            success("Connected successfully")
            setFromtokenState()
            setToTokenState()
          }
        } else {
          error("Please connect valid network")
        }
      } catch (error) {

      }
    } else if (window.web3) {
      let web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert('Non-Ethereum browser detected. Please install MetaMask plugin');
    };
  }
  const handlechangeWallet = (data) => {
    setAddress(data[0])
  }
  const error = (msg) => {
    toast.error(msg, {
      toastId: "customId"
    });
  }
  const success = (msg) => {
    toast.success(msg, {
      toastId: "customSId"
    });
  }

  const changeFromState = (value) => {
    if (FromState?.network === "Binance") {
      if (value?.token_symbol === "BUSD") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RBUSD")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "BTCB") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RBTC")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "USDT") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RUSDT")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "WBTC") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RBTC")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "WETH") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RETH")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "WUSD") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RUSD")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "TRX") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RTRX")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "XLM") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RXLM")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "XRP") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RXRP")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "BNB") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RBNB")
        setToTokenState(getToken[0]);
      }
    }
     // Realchain to Binance token change
    else if (FromState?.network === "Realchain" && ToState?.network === "Binance") {
      if (value?.token_symbol === "RBUSD") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "WBUSD")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "RUSDT") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "WUSDT")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "RBTC") {
        console.log("value?.token_symbol",value?.token_symbol);
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "BTCB")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "RETH") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "WETH")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "RUSD") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "WUSD")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "RTRX") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "WTRX")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "RXLM") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "XLM")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "RXRP") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "WXRP")
        setToTokenState(getToken[0]);
      }
    }
     // Realchain to Ethereum token change
    else if (FromState?.network === "Realchain" && ToState?.network === "Ethereum") {

      if (value?.token_symbol === "RBUSD") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "BUSD")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "RUSDT") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "WUSDT")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "RBTC") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "WBTC")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "RETH") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "WETH")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "RUSD") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "WUSD")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "RTRX") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "WTRX")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "RXLM") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "XLM")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "RXRP") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "WXRP")
        setToTokenState(getToken[0]);
      }
      // Ethereum token change
    }else if (FromState?.network === "Ethereum") {
      if (value?.token_symbol === "BUSD") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RBUSD")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "WBNB") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RBNB")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "USDT") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RUSDT")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "WBTC") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RBTC")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "ETH") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RETH")
        setToTokenState(getToken[0]);
      }     
      if (value?.token_symbol === "WUSD") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RUSD")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "WTRX") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RTRX")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "WXLM") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RXLM")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "WXRP") {
        setFromtokenState(value);
        let getToken = TochaintokenData.filter(name => name.token_symbol === "RXRP")
        setToTokenState(getToken[0]);
      }
    }

  }

  const changeToState = (value) => {
    if (ToState?.network === "Realchain" && FromState?.network === "Binance") {
      if (value?.token_symbol === "RBUSD") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "BUSD")
        setFromtokenState(getToken[0]);
      }
      if (value?.token_symbol === "RBTC") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "BTCB")
        setFromtokenState(getToken[0]);
      }
      if (value?.token_symbol === "RUSDT") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "USDT")
        setFromtokenState(getToken[0]);
      }
      if (value?.token_symbol === "RUSD") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "WUSD")
        setFromtokenState(getToken[0]);
      }
      if (value?.token_symbol === "RTRX") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "TRX")
        setFromtokenState(getToken[0]);
      }
      if (value?.token_symbol === "RXLM") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "XLM")
        setFromtokenState(getToken[0]);
      }
      if (value?.token_symbol === "RXRP") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "XRP");
        setFromtokenState(getToken[0]);
      }
      if (value?.token_symbol === "RBNB") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "BNB");
        setFromtokenState(getToken[0]);
      }
    }
    
    else if (ToState?.network === "Realchain" && FromState?.network === "Ethereum") {
      if (value?.token_symbol === "RBUSD") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "BUSD")
        setFromtokenState(getToken[0]);
      }
      if (value?.token_symbol === "RBTC") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "WBTC")
        setFromtokenState(getToken[0]);
      }
      if (value?.token_symbol === "RUSDT") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "USDT")
        setFromtokenState(getToken[0]);
      }
      if (value?.token_symbol === "RUSD") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "WUSD")
        setFromtokenState(getToken[0]);
      }
      if (value?.token_symbol === "RTRX") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "WTRX")
        setFromtokenState(getToken[0]);
      }
      if (value?.token_symbol === "RXLM") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "WXLM")
        setFromtokenState(getToken[0]);
      }
      if (value?.token_symbol === "RXRP") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "WXRP");
        setFromtokenState(getToken[0]);
      }
    }
    else if (ToState?.network === "Binance") {
      if (value?.token_symbol === "BUSD") {
        setFromtokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "RUSD")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "USDT") {
        setFromtokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "RUSDT")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "BTCB") {
        setFromtokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "RBTC")
        setToTokenState(getToken[0]);
      }
      if (value?.token_symbol === "WUSD") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "RUSD")
        setFromtokenState(getToken[0]);
      }
      if (value?.token_symbol === "TRX") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "RTRX")
        setFromtokenState(getToken[0]);
      }
      if (value?.token_symbol === "XLM") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "RXLM")
        setFromtokenState(getToken[0]);
      }
      if (value?.token_symbol === "XRP") {
        setToTokenState(value);
        let getToken = FromchaintokenData.filter(name => name.token_symbol === "RXRP");
        setFromtokenState(getToken[0]);
      }
    }

  }

  if (ethereum !== undefined) {
    ethereum.on('chainChanged', async function (chain) {
      if (chain === "0x38" || chain === "0x79" || chain === "0x1") {
        handleConnectWallet();
      } else {
        error("Please connect valid network")
        setAddress(null)
      }

    })
    ethereum.on('accountsChanged', handlechangeWallet)
  }

  const binanceConnect = async () => {
    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(56) }]
        });
      } catch (error) {
        if (error.code === 4902) {
          addNetwork(
            56,
            "BNB CHAIN mainnet",
            "https://bscnode1.anyswap.exchange",
            "https://bscscan.com",
            "BNB",
            "BNB",
            18
          )
        }
      }

    }
  }

  const ethereumConnect = async () => {
    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Web3.utils.toHex(1) }]
      });
    }
  }
  const switchNetwork = async (chainid) => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Web3.utils.toHex(chainid) }]
      });
    } catch (error) {

    }
  }
  const addNetwork = async (chainId, chain_name, rpcurl, blockurl, name, symbol, decimals) => {
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: Web3.utils.toHex(chainId),
            chainName: chain_name,
            rpcUrls: [rpcurl],
            blockExplorerUrls: [blockurl],
            nativeCurrency: {
              name: name,
              symbol: symbol,
              decimals: decimals
            }
          }]
      });
    } catch (addError) {

    }
  }
  const SwapTransfer = async () => {


    if (FromState.network === "Binance") {
      let web3 = new Web3(window.web3.currentProvider);
      let getChainID = await web3.eth.getChainId();
      if (getChainID !== 121) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.toHex(121) }]
          });
        } catch (error) {
          if (error.code === 4902) {
            addNetwork(
              121,
              "Real Mainet",
              "https://rcl-dataseed1.rclsidechain.com/",
              "https://rclscan.com",
              "REAL",
              "REAL",
              18
            )
          }
        }
      }
      setToState(bscnet)
      setFromState({
        network: "Realchain",
        tokenName: "Realchain RCL"
      })

      getChainData(RCLnet.network)
    } else if (FromState.network === "Ethereum") {
      let web3 = new Web3(window.web3.currentProvider);
      let getChainID = await web3.eth.getChainId();
      if (getChainID !== 121) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.toHex(121) }]
          });
        } catch (error) {
          if (error.code === 4902) {
            addNetwork(
              121,
              "Real Mainet",
              "https://rcl-dataseed1.rclsidechain.com/",
              "https://rclscan.com",
              "REAL",
              "REAL",
              18
            )
          }
        }

      }
      setToState(Ethnet)
      setFromState(RCLnet)

      getChainData(RCLnet.network)
    } else if (FromState.network === "Realchain" && ToState.network === "Binance") {
      let web3 = new Web3(window.web3.currentProvider);
      let getChainID = await web3.eth.getChainId();
      if (getChainID === 56) {
      } else {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.toHex(56) }]
          });
        } catch (error) {
          if (error.code === 4902) {
            addNetwork(
              56,
              "BNB Smart Chain Testnet",
              "https://data-seed-prebsc-2-s3.binance.org:8545",
              "https://testnet.rclscan.com",
              "BNB",
              "tBNB",
              18
            )
          }

        }

      }
      setToState(RCLnet)
      setFromState(bscnet)
      getChainData(bscnet.network)
    } else if (FromState.network === "Realchain" && ToState.network === "Ethereum") {
      let web3 = new Web3(window.web3.currentProvider);
      let getChainID = await web3.eth.getChainId();
      if (getChainID !== 1) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.toHex(1) }]
          });
        } catch (error) {
          if (error.code === 4902) {
            addNetwork(
              1,
              "Goerli test network",
              "https://goerli.infura.io/v3/",
              "https://goerli.etherscan.io",
              "GoerliETH",
              "GoerliETH",
              18
            )
          }
        }

      }
      setToState(RCLnet)
      setFromState(Ethnet)
      getChainData(Ethnet.network)
    }
  }


  const handleChange = (from, to) => {

  }

  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path='/' exact element={<>
            <Header changeFromState={changeFromState} changeToState={changeToState} FromchaintokenData={FromchaintokenData} TochaintokenData={TochaintokenData} bscnet={bscnet} RCLnet={RCLnet} Ethnet={Ethnet} changChain={changChain} connect={handleConnectWallet} handleChange={handleChange} eth={(e) => setEthValue(e.target.value)} address={address} chainid={chainid} chainState={chainState} ></Header>
            <Mainpage FromtokenState={FromtokenState} TotokenState={TotokenState} FromchainData={FromchainData} TochainData={TochainData} address={address} ToState={ToState} FromState={FromState} SwapTransfer={SwapTransfer} chainid={chainid}  ></Mainpage>
            <Footer></Footer>
          </>}>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
