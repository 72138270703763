import React, { useRef, useState } from 'react'

import chainLogo from '../assets/images/logo/logo.png';

import metalogo from '../assets/images/logo/metamask.svg';
import imtoken from '../assets/images/logo/imtoken.svg';
import trust from '../assets/images/logo/trust.png';
import coinbase from '../assets/images/logo/coinbase.png';
import walletconnect from '../assets/images/logo/walletconnect.svg';

import eth from '../assets/images/logo/eth.png';
import busd from '../assets/images/logo/busd.png';
import aave from '../assets/images/logo/aave.png';

import { Navbar, Nav, Dropdown, Collapse } from 'bootstrap-4-react';
import { Modal } from 'bootstrap-4-react';
import { Form } from 'bootstrap-4-react';
import $ from 'jquery'
import { toast } from 'react-toastify';
import '../../src/App.css'
import { Button } from 'bootstrap-4-react/lib/components';

export default function Header(props) {
  const { 
    connect,
    address,
    chainid,
    chainState,
    eth,
    handleChange,
    bscnet,
    RCLnet,
    Ethnet,
    changChain,
    FromchaintokenData,
    TochaintokenData,
    changeFromState,
    changeToState
  } = props;




  if (address) {
    window.$("#walletmodal").modal("hide");;
  }
  const textAreaRef = useRef(null);
  const ethValue = (e) => {
    let getvalue = e.target.value.split('-')
    if (getvalue[0] === "Ethereum" && getvalue[1] === "Realchain") {
      changChain(Ethnet, RCLnet);
    } else if (getvalue[0] === "Binance" && getvalue[1] === "Realchain") {
      changChain(bscnet, RCLnet);
    }
  }
  const CopyLink = () => {
    var dummy = document.createElement('input'),

      text = document.getElementsByClassName('copy')[0].innerHTML;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    toast.success("Copied")
  }
  return (
    <Navbar expand="lg" light bg="">
      <div className='container'>
        <Navbar.Brand href="#">
          <img src={chainLogo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggler target="#navbarSupportedContent" />
        <Collapse navbar id="navbarSupportedContent">
          <Navbar.Nav ml="auto">
            {!address ?
              <Nav.Item>
                <Nav.Link className="w-btn" data-toggle="modal" data-target="#walletmodal">
                  <i className="fas fa-wallet" ></i> Connect Wallet
                </Nav.Link>
              </Nav.Item>
              : null}
            {address ?
              <Nav.Item>
                <Nav.Link href="#" className="wallet-bg">
                  <i className="fas fa-wallet"></i>
                  <span className='copy' ref={textAreaRef} >{address}</span>
                </Nav.Link>

              </Nav.Item>
              : null}
            {address ? <i className="fas fa-copy copybuton" onClick={CopyLink} ></i> : null}



            <Nav.Item dropdown>

              <Form.Select aria-label="Default select example" name="option" className='formRight' onChange={(e) => ethValue(e)} >
                {chainState && chainState?.map((e, index) => (
                  <>
                    <option key={index} value={e?.From + "-" + e?.To}>{e?.From} - {e?.To}</option>

                  </>
                ))}
              </Form.Select>


            </Nav.Item>
          </Navbar.Nav>
        </Collapse>
      </div>
      <Modal id="walletmodal" fade>
        <Modal.Dialog centered>
          <Modal.Content>
            <Modal.Body>

              <Modal.Close>
                <span aria-hidden="true">&times;</span>
              </Modal.Close>

              <div className="row">

                <div className="col-md-12 pl-0 pr-0">
                  <div className="w-bx r-line b-line">
                    <a href='#' onClick={() => connect()}>
                      <img src={metalogo} alt="" />
                      <h3>MetaMask</h3>
                      <p>Connect to your MetaMask Wallet</p>
                    </a>
                  </div>
                </div>


              </div>
            </Modal.Body>

          </Modal.Content>
        </Modal.Dialog>
      </Modal>


      <Modal id="settingmodal" fade className="setting-pop">
        <Modal.Dialog centered>
          <Modal.Content>
            <Modal.Header>
              <h5 className='modal-title'>
                <i className="fas fa-cog"></i>
                <br />
                Settings
              </h5>
              <button type="button" className='close' data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className='page-frm'>


                <Form>
                  <Form.Group>
                    <label >Custom Ethereum RPC URL</label>
                    <Form.Input type="text" placeholder="" />
                  </Form.Group>

                  <Form.Group>
                    <label >Custom Gnosis Chain RPC URL</label>
                    <Form.Input type="text" placeholder="" />
                  </Form.Group>

                  <button type="submit" className='btn btn-submit'>Submit</button>

                </Form>

              </div>
            </Modal.Body>

          </Modal.Content>
        </Modal.Dialog>
      </Modal>

      <Modal id="from-coinModal" fade className="coin-pop">
        <Modal.Dialog centered>
          <Modal.Content>

            <Modal.Body>

              <Modal.Close>
                <span aria-hidden="true">&times;</span>
              </Modal.Close>

              <div className='dflex'>

                <div>
                  <h5>Select a Token</h5>
                  <p>Search Name or Paste Token Contract Address</p>
                </div>



              </div>

              <div className='slt'>

                <Form.Group>
                  <Form.Input type="text" placeholder="Search" />
                </Form.Group>

                <span className='srch'>
                  <a href=''><i className="fas fa-search"></i></a>
                </span>

              </div>

              <div className='coin-list'>
                <div className='coin-padd'>

                  {FromchaintokenData?.length > 0 && FromchaintokenData?.map((value, index) => {
                    return <button key={index} className='coin-btn' onClick={() => {
                      changeFromState(value)
                      window.$("#from-coinModal").modal("hide");
                    }}>
                      <div key={index} className='coin-div'>
                        <div className='coin-left'>

                          <p>{value.token_symbol}</p>
                        </div>
                        <p className="css-p">{value.token_name}</p>
                      </div>
                    </button>
                  })}


                </div>

              </div>

            </Modal.Body>

          </Modal.Content>
        </Modal.Dialog>
      </Modal>
      <Modal id="to-coinModal" fade className="coin-pop">
        <Modal.Dialog centered>
          <Modal.Content>

            <Modal.Body>

              <Modal.Close>
                <span aria-hidden="true">&times;</span>
              </Modal.Close>

              <div className='dflex'>

                <div>
                  <h5>Select a Token</h5>
                  <p>Search Name or Paste Token Contract Address</p>
                </div>



              </div>

              <div className='slt'>

                <Form.Group>
                  <Form.Input type="text" placeholder="Search" />
                </Form.Group>

                <span className='srch'>
                  <a href=''><i className="fas fa-search"></i></a>
                </span>
              </div>

              <div className='coin-list'>
                <div className='coin-padd'>
                  {TochaintokenData?.length > 0 && TochaintokenData?.map((value, index) => {
                    return <button key={index} className='coin-btn' onClick={() => {
                      changeToState(value)
                      window.$("#to-coinModal").modal("hide");;
                    }}>
                      <div key={index} className='coin-div'>
                        <div className='coin-left'>

                          <p>{value.token_symbol}</p>
                        </div>
                        <p className="css-p">{value.token_name}</p>
                      </div>
                    </button>
                  })}
                </div>

              </div>

            </Modal.Body>

          </Modal.Content>
        </Modal.Dialog>
      </Modal>

    </Navbar>

  )
}