import React from 'react'

function footer() {
  return (
    <div className="copyright">
<div className="container">
<div className="row">
<div className="col-md-12">
<p>© 2022 Realchain Link.</p>
</div>
</div>
</div>

</div>

  )
}

export default footer