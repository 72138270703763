import React, { useEffect, useState } from 'react'

import ethlogo from '../assets/images/logo/eth.png';
import dropimg from '../assets/images/logo/drop-down.svg';
import reallogo from '../assets/images/logo/real.png';

import unlock from '../assets/images/logo/unlock.svg';
import transfer from '../assets/images/logo/transfer.svg';
import Web3 from 'web3';
import { Form } from 'bootstrap-4-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import { socket } from '../Service/socket';

function Mainpage(props) {
    const {
        address,
        chainid,
        Changechain,
        FromState,
        ToState,
        SwapTransfer,
        FromchaintokenData,
        TochaintokenData,
        FromtokenState,
        TotokenState,
        FromchainData,
        TochainData
    } = props;
    const [amount, setamount] = useState(0);
    const [minRCL, setminRCL] = useState(0);
    const [maxRCL, setmaxRCL] = useState(1);
    const [available, setAvailable] = useState(0);
    const [addressstate, setaddressstate] = useState("")
    const [lockTransactionId, setlockTransactionId] = useState(null)
    const [lockingConfirm, setlockingConfirm] = useState(false)
    const [loading, setLoading] = useState(false);
    const [estimate, setestimate] = useState(false);
    const [estimatedAmount, setestimateAmount] = useState("");
    const [estimatefees, setestimatefees] = useState("");
    const [estimategas, setestimategas] = useState("");
    const [currency, setcurrency] = useState("");


    const getMaxbalance = async (data) => {
        if (address) {
            setaddressstate(address)
            if (window.web3) {
                let web3 = new Web3(window.web3.currentProvider);
                let getBalance = await web3.eth.getBalance(address);
                let getFromwei = Web3.utils.fromWei(getBalance, "ether")
                formik.setFieldValue("fromAmount", getFromwei)
                formik.setFieldValue("fromAddress", address.toString())
            }
        } else {
            toast.error("Please connect you wallet")
        }
    }
    useEffect(() => {
        formik.setFieldValue("fromAmount", 0)
    }, [FromtokenState])
    useEffect(() => {
        socket.on('getSettings-res', (msg) => {
            setminRCL(msg[0].min_token);
            setmaxRCL(msg[0].max_token + 1)
        })
        socket.on('get-token-balance-faild', (msg) => {

        })
        socket.on('get-token-balance-suceess', (msg) => {
            if (msg?.tokenBalance > 0) {
                setAvailable(msg?.tokenBalance)
            }
        })
        socket.on('lock-token-faild', (msg) => {
            setLoading(false)
            toast.error(msg)
        })
        socket.off("lock-token-success").on('lock-token-success', async (msg) => {
            setLoading(false)
            let formikAmount = msg?.amount;
            let web3 = new Web3(window.web3.currentProvider);
            var MyContract = new web3.eth.Contract(JSON.parse(process.env.REACT_APP_BRIDGE_CONTRACT_ADDRESS_ABI), msg?.Fromchain?.bridge_Contract?.Contract_address);
            if (msg?.fromToken?.token_symbol === "BNB" || msg?.fromToken?.token_symbol === "ETH") {
                let amount = web3.utils.toWei(formikAmount.toString(), 'ether')
                MyContract.methods.depositNativeCoin().send({
                    from: msg?.address,
                    to: msg?.Fromchain?.bridge_Contract?.Contract_address,
                    value: amount,
                }).then(res => {
                    setLoading(false)
                    let lockconfirmToken = {
                        transactionId: msg._id
                    }
                    socket.emit("lock-token-confirmed", lockconfirmToken)
                })
            } else {
                var tokenContract = new web3.eth.Contract(JSON.parse(msg?.fromToken?.contract_ABI), msg?.fromToken?.contract_address);
                let amount = web3.utils.toWei(formikAmount.toString(), 'ether')
                MyContract.methods.deposit(msg?.fromToken?.contract_address, amount).send({
                    from: msg?.address,
                    to: msg?.fromToken?.contract_address,
                    value: "0x0",
                    data: tokenContract.methods.transfer(msg?.Fromchain?.bridge_Contract?.Contract_address, amount).encodeABI()
                }).then(res => {
                    setLoading(false)
                    let lockconfirmToken = {
                        transactionId: msg._id
                    }
                    socket.emit("lock-token-confirmed", lockconfirmToken)
                })
            }


        })
        socket.on('lock-token-confirmed-faild', (msg) => {
            toast.error(msg)
        })
        socket.on('estimate-success', (msg) => {
            
            if (msg.amount) {

                
                setLoading(false)
                formik.setFieldValue("toAmount", msg.amount)
                // let convertAmount = Web3.utils.fromWei((msg.Fees.toFixed(0)).toString(), 'ether')               
                setestimateAmount(msg.amount)
                setestimatefees(msg.Fees)
                setestimategas(Number(msg.gasAmount).toFixed(7))
                setcurrency(msg.currency);
                setestimate(true)
            }

        })
        socket.on('estimate-faild', (msg) => {

        })
        socket.on('lock-token-confirmed-success', (msg) => {
            setLoading(true)
            let postValue = {
                address: msg?.address,
                from_amount: msg?.amount,
                to_acmount: msg?.amount,
                Fromchain: msg?.Fromchain?.chain_name,
                Fromtoken: msg?.fromToken.token_symbol,
                Tochain: msg?.Tochain?.chain_name,
                Totoken: msg?.toToken?.token_symbol,
                Address: msg?.address,
                Amount: msg?.amount
            }
            socket.emit('swap', postValue)
        })
    }, [socket])
    useEffect(() => {
        let getTokenData = {
            tokenId: TotokenState?._id
        }
        socket.emit("get-token-balance", (getTokenData))
    }, [FromtokenState])
    useEffect(() => {
        socket.emit("getSettings", "getSettings");

    }, [])

    const checkBalance = async (value) => {
        let web3 = new Web3(window.web3.currentProvider);

        if (FromtokenState?.contract_ABI) {

            if (FromtokenState.token_symbol === "BNB") {
                let balance = await web3.eth.getBalance(address);
                let toWei = web3.utils.toWei(value.toString(), "ether");
                if (parseInt(toWei) <= parseInt(balance)) {
                    return true;
                } else {
                    return false;
                }
            } else if (FromtokenState.token_symbol === "ETH") {
                let balance = await web3.eth.getBalance(address);
                let toWei = web3.utils.toWei(value.toString(), "ether");
                if (parseInt(toWei) <= parseInt(balance)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                var tokenContract = new web3.eth.Contract(JSON.parse(FromtokenState.contract_ABI), FromtokenState.contract_address);
                let balanceOf = await tokenContract.methods.balanceOf(address).call()
                let decimals = await tokenContract.methods.decimals().call(); 
                let amount = Number(formik.values.addLiquidity * Math.pow(10, decimals));
                // let toWei = web3.utils.toWei(value.toString(), "ether");
                if (parseInt(amount) <= parseInt(balanceOf)) {
                    return true;
                } else {
                    return false;
                }
            }

        }
    }
    const getAvailableBalance = async (value) => {
      
        if (value <= available) {
            return true
        }
    }
    const Swapschema = Yup.object().shape({
        fromAmount: Yup.number()
            .typeError("That doesn't look like a number")
            .positive("Number must be positive")
            .required('Please enter a amount')
            .test("Min transafer", `Min amount of token ${minRCL} RCL`, (value) => value >= minRCL)
            .test("Max transafer", `Max amount of token ${maxRCL - 1} RCL`, (value) => value < maxRCL)
            .test("Check available balance", "You can't lock this token", getAvailableBalance)
            .test("Check balance", "You don't have a balance", checkBalance)

    });
    const formik = useFormik({
        initialValues: {
            fromAmount: 0,
            toAmount: 0
        },
        validationSchema: Swapschema,
        onSubmit: values => {

            swapTransferBN()


        },
    });
    const swapTransferBN = async () => {
        if (address && (FromtokenState === undefined)) {
            toast.error("Please Select Token")
            return false;
        }
        if (address) {
            
            if (window.web3) {
                let formikAmount = formik.values.fromAmount;
                let web3 = new Web3(window.web3.currentProvider);
                if (FromtokenState.token_symbol === "BNB" || FromtokenState.token_symbol === "ETH") {
                    let amount = web3.utils.toWei(formikAmount, "ether");
                    setLoading(true)
                    let locktokenData = {
                        Fromchain: FromchainData._id,
                        Tochain: ToState.network,
                        fromToken: FromtokenState._id,
                        toToken: TotokenState._id,
                        address: address,
                        amount: formikAmount
                    }
                    socket.emit("lock-token", locktokenData);
                } else {
                    var MyContract = new web3.eth.Contract(JSON.parse(process.env.REACT_APP_BRIDGE_CONTRACT_ADDRESS_ABI), FromchainData?.bridge_Contract?.Contract_address);
                    var tokenContract = new web3.eth.Contract(JSON.parse(FromtokenState?.contract_ABI), FromtokenState?.contract_address);
                    let getdecimal = await tokenContract.methods.decimals().call();
                    let amount = Number(Math.floor(formikAmount * Math.pow(10, getdecimal))).toString();
                    try {
                        tokenContract.methods.approve(FromchainData?.bridge_Contract?.Contract_address, amount).send({
                            from: address,
                        }).then(res => {
                            setLoading(true)
                            let locktokenData = {
                                Fromchain: FromchainData._id,
                                Tochain: ToState.network,
                                fromToken: FromtokenState._id,
                                toToken: TotokenState._id,
                                address: address,
                                amount: formikAmount
                            }
                            socket.emit("lock-token", locktokenData)
                        }).on(res => {
                            console.log("error", res)
                        })
                    } catch (error) {
                        console.log("error", error)
                    }
                }
            }
        } else {
            toast.error("Please connect you wallet")
        }
    }
    const error = (msg) => {
        toast.error(msg, {
            toastId: "customId"
        });
    }
    const success = (msg) => {
        toast.success(msg, {
            toastId: "customSId"
        });
    }
    useEffect(() => {
        if (address) {
            setaddressstate(address)
        }
    }, [address])
    useEffect(() => {
        socket.on('swaped', (value) => {
            setLoading(false)
            success("Transaction complete")
        });
        socket.on('swap-fail', (value) => {
            setLoading(false)
            error(value)
        });
    }, [socket])

    const changeChain = (e) => {
        if (address && FromtokenState) {
            formik.setFieldValue("fromAmount", e.target.value)
            let locktokenData = {
                Fromchain: FromchainData._id,
                Tochain: ToState.network,
                fromToken: FromtokenState._id,
                toToken: TotokenState._id,
                Address: address,
                Amount: e.target.value
            }
            setLoading(true)
            socket.emit('estimate', locktokenData)
        }else if(!address){
            toast.error("Please connect you wallet");
        }else {
            toast.error("Please select token");
        }
    }

    
    return (
        <section className="page-title">
            <div className="container">
                <div className="trans-bx">
                    <div className="trans-top">
                        <div className="css-left">
                            <p>From</p>
                            {FromState.network}
                        </div>
                        <div className="css-right">
                            <p>To</p>
                            {ToState.network}
                        </div>
                    </div>
                    <Form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-5 col-12">
                                <div className="from-bx">
                                    <a data-toggle="modal"
                                        data-target="#from-coinModal"
                                    >
                                        <div className="css-coindiv">
                                            <div className="css-coin">
                                                <img src={reallogo} alt="" />
                                            </div>

                                            <p>{FromtokenState ? FromtokenState?.token_symbol : "Select Token"}</p>

                                        </div>
                                    </a>
                                    <div className="css-input">
                                        <Form.Group>
                                            <Form.Input type="number" name="fromAmount" value={formik.values.fromAmount} onChange={(e) => changeChain(e)} placeholder="1" className="css-inputbx" />
                                        </Form.Group>


                                    </div>
                                    <div className='error'>{formik.errors.fromAmount}</div>
                                </div>
                            </div>
                            <div className="col-md-2 col-12">
                                <br />
                                <br />
                                <div className="trans-btn">
                                    <div className="ex-ic">
                                        <a onClick={SwapTransfer}>
                                            <i className="fas fa-exchange-alt"></i>
                                        </a>
                                    </div>
                                    {loading ? (
                                        <button className="t-btn mb-3">
                                            <i class="fa fa-circle-o-notch fa-spin"></i>Loading
                                        </button>
                                    ) : <button className="t-btn mb-3" type='submit'>Transfer <img src={transfer} alt="" /></button>}

                                </div>
                            </div>
                            <div className="col-md-5 col-12 ">
                                <div className="from-bx ">
                                    <a data-toggle="modal"
                                        data-target="#to-coinModal"
                                    >
                                        <div className="css-coindiv d-flex justify-content-end">
                                            <div className="css-coin">
                                                <img src={reallogo} alt="" />
                                            </div>
                                            <p>{TotokenState ? TotokenState?.token_symbol : "Select Token"}</p>

                                        </div>
                                    </a>
                                    <div className="css-input d-flex justify-content-end">

                                        <Form.Group>
                                            <Form.Input disabled value={formik.values.toAmount} onChange={(e) => (e)} name="toAmount" type="number" placeholder="1" className="css-inputbx" />
                                        </Form.Group>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </Form>


                    <div className="tran-btmdiv">
                        <div className="row">

                            <div className="col-md-4">
                            </div>

                            <div className="col-md-4">
                                <div className="limit-div">


                                    <ul>
                                        {estimate ? (
                                            <>
                                                <li>Receive amount<span>{estimatedAmount}&nbsp;{TotokenState ? TotokenState?.token_symbol : null}</span></li>
                                                <li>Fees<span>{estimatefees}&nbsp;{TotokenState ? TotokenState?.token_symbol : null}</span></li>
                                                <li>Gas estimate <span>{estimategas}&nbsp;{currency === "MATIC" ? "RCL" : currency}</span></li>
                                            </>
                                        ) : null}
                                        <li>Max per Tx <span>{maxRCL - 1} {FromtokenState ? FromtokenState?.token_symbol : null}</span></li>
                                        <li>Min per Tx <span>{minRCL} &nbsp;{FromtokenState ? FromtokenState?.token_symbol : null}</span></li>

                                    </ul>

                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>

        </section>



    )

}

export default Mainpage;
